.l-grid {
  position: relative;
}

@media only screen and (min-width: 768px) {
  .l-grid {
    display: grid;
    grid-template-columns: 192px auto;
  }
}

.l-grid--gap {
  gap: 0 1rem;
}

.l-grid--secondary {
  grid-template-columns: 100px auto;
}

.l-grid-item--span-full {
  grid-column: 1 / -1;
}

.l-grid-item--second {
  grid-column: 2;
}
