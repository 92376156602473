.t-margin-0 {
  margin: 0;
}

.t-margin-bottom-1 {
  margin-bottom: 1rem;
}

.t-margin-bottom-2 {
  margin-bottom: 2rem;
}
