.c-title {
  display: block;
}

@media only screen and (min-width: 768px) {
  .c-title__primary {
    white-space: nowrap;
    display: flex;
    justify-content: right;
  }
}
