.c-row {
  margin: 0 0 1rem;
}

.c-row__content :last-child {
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) {
  .c-row__label.c-row__label--offset {
    text-align: right;
  }
}
