@font-face {
  font-family: 'ITC Officana Sans';
  src: url('../../fonts/itc_officina_sans_bold-webfont.woff2') format('woff2'), url('../../fonts/itc_officina_sans_bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'ITC Officana Sans';
  src: url('../../fonts/itc_officina_sans_book_regular-webfont.woff2') format('woff2'), url('../../fonts/itc_officina_sans_book_regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
