.l-columns {
  margin: 0 0 2rem;
}

@media only screen and (min-width: 1280px) {
  .l-columns {
    columns: 2;
    column-gap: 2rem;
  }
}

.l-columns--2 {
  columns: 2;
}
